import { render, staticRenderFns } from "./ClatTaxTable.vue?vue&type=template&id=06f329e5&scoped=true&"
import script from "./ClatTaxTable.vue?vue&type=script&lang=ts&"
export * from "./ClatTaxTable.vue?vue&type=script&lang=ts&"
import style0 from "./ClatTaxTable.vue?vue&type=style&index=0&id=06f329e5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f329e5",
  null
  
)

export default component.exports