import { TableData } from "@/modules/genprox/modules/profile/store/types";

export interface TemplatesState {
  templatesTable: TableData<any>,
  templatesTableIsBusy: boolean,
}

export interface Template {
  id?: string,
  title: string,
  description: string | null,
  content: string,
  status: TemplateStatuses,
  isAttachment: boolean,
  attachments: TemplateAttachment[],
  authorName?: string,
  createdAt?: string,
  updatedAt?: string,
}

export interface TemplateAttachment {
  id?: string,
  name: string,
  type: 'TEMPLATE' | 'FILE',
  referenceId: string,
  path?: string | null,
}

export interface AttachmentListItem {
  selected: boolean,
  referenceId: string,
  name: string,
}

export interface AttachmentObject {
  fileId: string,
  fileName: string,
}

export interface AgreementObject {
  agreementFileId: string,
  agreementName: string,
  attachments: AttachmentObject[],
}

export enum TemplateStatuses {
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
  ACTIVE = 'ACTIVE',
}

export enum TemplateActions {
  DRAFT = 'draftTemplate',
  ARCHIVED = 'archiveTemplate',
  ACTIVE = 'activateTemplate',
}