var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subscription-table"
  }, [_c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.deletingCampaignId,
      expression: "deletingCampaignId"
    }],
    attrs: {
      "isLoading": _vm.isLoading,
      "header": "Are you sure?",
      "description": "Do you really want to archive this campaign?",
      "cancelText": "No",
      "confirmText": "Yes"
    },
    on: {
      "close": _vm.closeDeleteConfirmationModal,
      "cancel": _vm.closeDeleteConfirmationModal,
      "confirm": _vm.deleteCampaign
    }
  }), _c('div', {
    staticClass: "subscription-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "subscriptionsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "show-empty": true,
      "fields": _vm.tableFieldsComputed,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-left code"
        }, [_c('span', [_vm._v(_vm._s(rowData.item.code))]), _vm.hasError(rowData.item) ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              variant: 'danger',
              boundary: 'viewport'
            },
            expression: "{ variant: 'danger', boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "code__info",
          attrs: {
            "title": _vm.getErrorMessage(rowData.item)
          }
        }, [_vm._v("i")]) : _vm._e()])];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item3;

        return [_c('div', {
          class: [rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.sourceId ? 'hoverable' : ''],
          on: {
            "click": function click($event) {
              var _rowData$item2;

              return _vm.goToSourceProfile(rowData === null || rowData === void 0 ? void 0 : (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.sourceId);
            }
          }
        }, [_vm._v(_vm._s(rowData === null || rowData === void 0 ? void 0 : (_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.sourceName))])];
      }
    }, {
      key: "cell(startDate)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.startDate)))])])];
      }
    }, {
      key: "cell(endDate)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.endDate)))])])];
      }
    }, {
      key: "cell(agreementDate)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.agreementDate)))])])];
      }
    }, {
      key: "cell(capitalValue)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.capitalValue, 2)) + " PLN")])])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.instrumentCount)))])])];
      }
    }, {
      key: "cell(seriesOfShares)",
      fn: function fn(rowData) {
        var _rowData$item4;

        return [_c('div', {
          staticClass: "series-of-shares"
        }, _vm._l((_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : _rowData$item4.seriesOfShares, function (share, index) {
          var _rowData$item5, _rowData$item6, _rowData$item7, _rowData$item7$series, _rowData$item8;

          return _c('span', {
            key: "".concat((_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : _rowData$item5.name, "-").concat(index),
            staticClass: "share",
            attrs: {
              "id": "".concat((_rowData$item6 = rowData.item) === null || _rowData$item6 === void 0 ? void 0 : _rowData$item6.id, "-").concat(index)
            }
          }, [_vm._v(" " + _vm._s(share === null || share === void 0 ? void 0 : share.name) + _vm._s(index + 1 === ((_rowData$item7 = rowData.item) === null || _rowData$item7 === void 0 ? void 0 : (_rowData$item7$series = _rowData$item7.seriesOfShares) === null || _rowData$item7$series === void 0 ? void 0 : _rowData$item7$series.length) ? '' : ', ') + " "), _c('b-tooltip', {
            attrs: {
              "boundary": "window",
              "placement": "bottom",
              "target": "".concat((_rowData$item8 = rowData.item) === null || _rowData$item8 === void 0 ? void 0 : _rowData$item8.id, "-").concat(index),
              "triggers": "hover"
            }
          }, [_c('table', {
            staticClass: "share-table"
          }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Instrument count:")]), _c('td', [_vm._v(_vm._s(_vm._f("thousandSeparator")(share === null || share === void 0 ? void 0 : share.instrumentCount)))])]), _c('tr', [_c('td', [_vm._v("Counter From:")]), _c('td', [_vm._v(_vm._s(_vm._f("thousandSeparator")(share === null || share === void 0 ? void 0 : share.counterFrom)))])]), _c('tr', [_c('td', [_vm._v("Counter To:")]), _c('td', [_vm._v(_vm._s(_vm._f("thousandSeparator")(share === null || share === void 0 ? void 0 : share.counterTo)))])]), _c('tr', [_c('td', [_vm._v("Nominal Value:")]), _c('td', [_vm._v(_vm._s(_vm._f("numberFormat")(share === null || share === void 0 ? void 0 : share.nominalValue, 2)) + " PLN")])]), _c('tr', [_c('td', [_vm._v("Issue Value:")]), _c('td', [_vm._v(_vm._s(_vm._f("numberFormat")(share === null || share === void 0 ? void 0 : share.issueValue, 2)) + " PLN")])])])])])], 1);
        }), 0)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: ['outline round', _vm.getStatusClassName(rowData.item.status)],
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(_vm._s(_vm.statusText(rowData.item.status)))])], 1)];
      }
    }, {
      key: "cell(target)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__target"
        }, [_c('sygni-progress-bar', {
          attrs: {
            "value": rowData.item.investmentReach,
            "max": rowData.item.capitalValue ? rowData.item.capitalValue : 0,
            "type": "simple"
          }
        })], 1)];
      }
    }, {
      key: "cell(investmentReach)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.investmentReach, 2)) + " PLN")])])];
      }
    }, {
      key: "cell(investmentValue)",
      fn: function fn(rowData) {
        var _rowData$item9;

        return [_c('div', {
          staticClass: "text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_rowData$item9 = rowData.item) === null || _rowData$item9 === void 0 ? void 0 : _rowData$item9.investmentValue, 2)) + " PLN")])])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "action"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.isMarketplaceAccountant ? 'Preview' : 'Edit',
            expression: "isMarketplaceAccountant ? 'Preview' : 'Edit'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el",
          on: {
            "click": function click($event) {
              _vm.$router.push({
                path: "/".concat(_vm.$route.path.includes('company') ? 'company' : 'fund', "/capital-rise/subscriptions/").concat(rowData.item.id)
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")(_vm.isMarketplaceAccountant ? 'Fund' : 'EDIT')
          }
        })])]), _c('div', {
          staticClass: "action"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Show subscriptions',
            expression: "'Show subscriptions'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('ARROW')
          }
        })])])])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("There are no subscriptions on your list")])];
      },
      proxy: true
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        return [_c('subscriptions-inner-table', {
          attrs: {
            "items": rowData.item.details,
            "subscription": rowData.item
          }
        }), _vm.showSubscriptionActions(rowData.item) ? _c('div', {
          staticClass: "add-product-container"
        }, [_vm.isAddAvailable(rowData.item) ? _c('sygni-rounded-button', {
          staticClass: "outline disabled",
          attrs: {
            "hoverable": true,
            "plus-icon": true
          },
          on: {
            "click": function click($event) {
              return _vm.addNewProduct(rowData.item.id);
            }
          }
        }, [_vm._v(" Add new investor ")]) : _vm._e(), _vm.isCancelAvailable(rowData.item) ? _c('sygni-rounded-button', {
          staticClass: "outline red ml-auto",
          on: {
            "click": function click($event) {
              return _vm.handleSubscriptionAction('cancel', rowData.item.id);
            }
          }
        }, [_vm._v("Cancel subscription")]) : _vm._e(), _vm.isPassVisible(rowData.item) ? _c('sygni-rounded-button', {
          staticClass: "outline primary ml-3",
          on: {
            "click": function click($event) {
              return _vm.openSubscriptionPassModal(rowData.item);
            }
          }
        }, [_vm._v("Authorize subscription")]) : _vm._e()], 1) : _vm._e()];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3, _data$fields4, _data$fields5, _data$fields6;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key)
          }, [((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.key) == 'code' ? _c('div', [_c('div', {
            staticClass: "totals totals--align-left"
          }, [_c('div', {
            staticClass: "totals__left"
          }, [_c('p', [_c('strong', [_vm._v("Total investors:")])])]), _c('div', {
            staticClass: "totals__right"
          }, [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])])])]) : _vm._e(), ((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'seriesOfShares' || ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) === 'instrumentCount' ? _c('div', {
            staticClass: "text-center"
          }, [_c('p', [_c('strong', [_vm._v("Total amount:")])])]) : _vm._e(), ((_data$fields4 = data.fields[i - 1]) === null || _data$fields4 === void 0 ? void 0 : _data$fields4.key) == 'investmentReach' ? _c('div', {
            staticClass: "text-right"
          }, [_c('div', {
            staticClass: "totals"
          }, [_c('div', {
            staticClass: "totals__left"
          }, _vm._l(Object.entries(_vm.totals.investmentReach), function (_ref) {
            var key = _ref[0],
                value = _ref[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(_vm.index))]);
          }), 0), _c('div', {
            staticClass: "totals__right"
          }, _vm._l(Object.entries(_vm.totals.investmentReach), function (_ref2) {
            var key = _ref2[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0)])]) : _vm._e(), ((_data$fields5 = data.fields[i - 1]) === null || _data$fields5 === void 0 ? void 0 : _data$fields5.key) == 'capitalValue' ? _c('div', {
            staticClass: "text-right"
          }, [_c('div', {
            staticClass: "totals"
          }, [_c('div', {
            staticClass: "totals__left"
          }, _vm._l(Object.entries(_vm.totals.capitalValue), function (_ref3) {
            var key = _ref3[0],
                value = _ref3[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(_vm.index))]);
          }), 0), _c('div', {
            staticClass: "totals__right"
          }, _vm._l(Object.entries(_vm.totals.capitalValue), function (_ref4) {
            var key = _ref4[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0)])]) : _vm._e(), ((_data$fields6 = data.fields[i - 1]) === null || _data$fields6 === void 0 ? void 0 : _data$fields6.key) == 'investmentValue' ? _c('div', {
            staticClass: "text-right"
          }, [_c('div', {
            staticClass: "totals"
          }, [_c('div', {
            staticClass: "totals__left"
          }, _vm._l(Object.entries(_vm.totals.investmentValue), function (_ref5) {
            var key = _ref5[0],
                value = _ref5[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(_vm.index))]);
          }), 0), _c('div', {
            staticClass: "totals__right"
          }, _vm._l(Object.entries(_vm.totals.investmentValue), function (_ref6) {
            var key = _ref6[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0)])]) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _vm.subscriptionConfirmationModalId ? _c('sygni-modal', {
    attrs: {
      "header": "Are you sure?",
      "description": "Please confirm you wish to continue without capital rise agreement generation and payment allocations.",
      "cancelText": "Cancel",
      "confirmText": "Continue",
      "isLoading": _vm.isSubscriptionModalLoading
    },
    on: {
      "confirm": _vm.forceSubscriptionPass,
      "cancel": _vm.closeSubscriptionConfirmationModal,
      "close": _vm.closeSubscriptionConfirmationModal
    }
  }) : _vm._e(), _c('landing-page-modal', {
    attrs: {
      "isLoading": _vm.isLandingPageModalLoading,
      "campaignData": _vm.campaignData,
      "show": _vm.showLandingPageModal
    },
    on: {
      "close": _vm.closeLandingPageModal
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }